import _ from 'underscore'
import equal from 'deep-equal'
import Events from '@/configuration/Events'
import Reader from '@/host/service/DNA/Reader'

/**
 * DNA Reader
 */
export default class DNA {
  constructor({ $bus, $store, storeFactory, ghostQueue, tokenReader }) {
    this.$bus = $bus
    this.$store = $store
    this.storeFactory = storeFactory
    this.ghostQueue = ghostQueue
    this.tokenReader = tokenReader
    this.reader = new Reader({ $store, $bus })
  }

  /**
   * Injects a DNA configuration identified by name optional
   */
  parse(rawDna, spaInit = false, propUpdate = false) {
    // Generate parsed dna
    const dna = this.reader.parse(rawDna)

    // Reset form status if the dna has changed
    if (!equal(dna, this.$store.state.dna)) this.$store.dispatch('resetForm')

    // Update the store with DNA info
    if (!propUpdate)
      this.$store.dispatch('update', {
        dna,
        amount: dna.amount,
        currency: dna.currency,
        country: dna.country,
        shopName: dna.shopName,
        orderId: dna.orderId || '',
        dnaTestCards: dna.testCards || [],
        riskAnalyser: dna.riskAnalyser || {},
        parsingToken: false
      })

    window.KR_TOKEN = dna

    // For spa mode, the spa init is required if the form is not already initialized
    const { spaMode, allIFramesReady } = this.$store.state
    if (!spaMode || spaInit || allIFramesReady) this.createForm(dna)

    return Promise.resolve(dna)
  }

  createForm(dna) {
    // Create form only if it is not already created
    if (!this.$store.state.forms.main) {
      const formId = `F${_.random(10000, 99999)}`
      this.$store.dispatch('addForm', {
        label: 'main',
        id: formId
      })
      this.$store.dispatch('registerCardFormModule', { id: formId })
      this.$store.dispatch('renderCardForm', formId)
      const store = this.storeFactory.create('actionNewForm', { formId, dna })
      this.ghostQueue.send(store)
      this.$bus.$emit(Events.krypton.form.new, { formId })
    }
  }
}
