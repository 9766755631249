import StoreConfiguration from '@/configuration/StoreConfiguration'

const getStoreEvents = () => {
  const conf = {}
  for (const key in StoreConfiguration) {
    conf[key] = `krypton.message.${key}`
  }
  return conf
}

export default {
  // Common
  krypton: {
    data: {
      newSplitForm: 'krypton.data.newSplitForm',
      newPopin: 'krypton.data.newPopin',
      newPopinRedirection: 'krypton.data.newPopinRedirection',
      newBrandButtons: 'krypton.data.newBrandButtons',
      newSmartFormButton: 'krypton.data.newSmartFormButton',
      echo: 'krypton.data.echo',
      testCards: 'krypton.data.testCards',
      language: {
        get: 'krypton.data.language.get',
        set: 'krypton.data.language.set'
      },
      errorAlert: 'krypton.data.errorAlert'
    },
    vue: {
      ready: 'krypton.vue.ready'
    },
    log: 'krypton.log',
    destroy: 'krypton.destroy',
    discount: 'krypton.discount',
    field: {
      event: 'krypton.field.event',
      focus: 'krypton.field.focus',
      clear: 'krypton.field.clear',
      length: 'krypton.field.length',
      blurAll: 'krypton.field.blurAll'
    },
    form: {
      relocation: 'krypton.form.relocation',
      remove: 'krypton.form.remove',
      add: 'krypton.form.add',
      enable: 'krypton.form.enable',
      disable: 'krypton.form.disable',
      config: 'krypton.form.config',
      pay: 'krypton.form.pay',
      backButton: 'krypton.form.backButton',
      ready: 'krypton.form.ready',
      showForm: 'krypton.form.showForm',
      hideForm: 'krypton.form.hideForm',
      submit: 'krypton.form.submit',
      loaded: 'krypton.form.loaded',
      help: {
        visibility: 'krypton.form.help.visibility'
      },
      label: {
        content: 'krypton.form.label.content',
        updated: 'krypton.form.label.updated'
      },
      button: {
        replace: 'krypton.form.button.replace'
      },
      error: {
        extraContent: 'krypton.form.error.extraContent'
      },
      icon: {
        brand: {
          visible: 'krypton.form.icon.brand.visible',
          invisible: 'krypton.form.icon.brand.invisible'
        }
      },
      brand: {
        forced: 'krypton.form.brand.forced'
      },
      new: 'krypton.form.new'
    },
    smartform: {
      ready: 'krypton.smartform.ready',
      paymentMethodSelected: 'krypton.smartform.paymentMethodSelected',
      warningFlash: 'krypton.smartform.warningFlash',
      spinner: {
        freeze: 'krypton.smartform.spinner.freeze',
        unfreeze: 'krypton.smartform.spinner.unfreeze'
      }
    },
    payment: {
      redirect: 'krypton.payment.redirect',
      start: 'krypton.payment.start',
      end: 'krypton.payment.end',
      done: 'krypton.payment.done',
      transaction: {
        get: 'krypton.payment.transaction.get'
      }
    },
    popin: {
      open: 'krypton.popin.open',
      close: 'krypton.popin.close',
      load: 'krypton.popin.load',
      footer: {
        logo: 'krypton.popin.footer.logo'
      }
    },
    simpleModal: {
      close: 'krypton.simpleModal.close'
    },
    error: 'krypton.error',
    warning: 'krypton.warning',
    util: {
      verification: {
        class: 'krypton.util.verification.class'
      },
      cursor: {
        animation: 'krypton.util.cursor.animation'
      }
    },
    iframes: {
      allReady: 'krypton.iframes.allReady'
    },
    simulator: {
      cards: {
        selected: 'krypton.simulator.cards.selected'
      },
      applePay: {
        ready: 'krypton.simulator.applePay.ready'
      }
    },
    toolbar: {
      created: 'krypton.toolbar.created',
      showTimeDetails: 'krypton.toolbar.showTimeDetails',
      showWarning: 'krypton.toolbar.showWarning',
      showCards: 'krypton.toolbar.showCards',
      warningPanel: {
        ready: 'krypton.toolbar.warningPanel.ready'
      },
      events: {
        cleaned: 'krypton.toolbar.events.cleaned'
      }
    },
    sync: {
      ready: 'krypton.sync.ready',
      global: 'krypton.sync.global',
      ghostReload: 'krypton.sync.ghostReload'
    },
    installments: {
      change: 'krypton.installments.change'
    },
    // Stores
    message: getStoreEvents()
  },
  // Iframes
  iframe: {
    relocation: 'iframe.relocation'
  },
  // Ghost
  ghost: {
    appleOrGooglePay: {
      payload: 'ghost.appleOrGooglePay.payload'
    }
  },
  // Slave
  slave: {
    configuration: {
      change: 'slave.configuration.change',
      parsed: 'slave.configuration.parsed',
      update: 'slave.configuration.update'
    },
    field: {
      hideFakeCaret: 'slave.field.hideFakeCaret',
      change: 'slave.field.change',
      clear: 'slave.field.clear',
      focus: {
        forced: 'slave.field.focus.forced'
      }
    }
  }
}
